//TODO:Corrigir nomeclatura deste arquivo.
export enum UserStatusEnum {
  ACTIVE = 'ATIVO',
  INACTIVE = 'INATIVO',
  REGISTER_ENABLED = 'REGISTRO LIBERADO',
  REGISTER_BLOCKED = 'REGISTRO BLOQUEADO',
}

export enum InstitutionStatusEnum {
  REGISTER_BLOCKED = 'REGISTER_BLOCKED',
  REGISTER_ENABLED = 'REGISTER_ENABLED',
}

export enum InstitutionContactTypeEnum {
  CELLPHONE = 'CELLPHONE',
}
