import { Button } from '@mui/material';
import { Colors } from 'theme';

export enum FormButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CANCEL_PRIMARY = 'cancel-primary',
  CANCEL_SECONDARY = 'cancel-secondary',
}

export interface FormButtonProps {
  type: FormButtonType;
  title: string;
  onClick: () => void;
  isSubmit?: boolean;
  isDisabled?: boolean;
}

export const FormButton = ({ type, title, onClick, isSubmit, isDisabled }: FormButtonProps): JSX.Element => {
  const setType = () => {
    switch (type) {
      case FormButtonType.PRIMARY:
        return 'contained';

      case FormButtonType.CANCEL_PRIMARY:
        return 'contained';

      case FormButtonType.SECONDARY:
        return 'text';

      case FormButtonType.CANCEL_SECONDARY:
        return 'text';
    }
  };

  const setBackground = () => {
    switch (type) {
      case FormButtonType.PRIMARY:
        return Colors.AZUL_GOV;

      case FormButtonType.SECONDARY:
        return 'transparent';

      case FormButtonType.CANCEL_SECONDARY:
        return 'transparent';

      case FormButtonType.CANCEL_PRIMARY:
        return Colors.VERMELHO_GOV;
    }
  };

  const setTextColor = () => {
    switch (type) {
      case FormButtonType.PRIMARY:
        return 'white';

      case FormButtonType.CANCEL_PRIMARY:
        return 'white';

      case FormButtonType.SECONDARY:
        return Colors.AZUL_GOV;

      case FormButtonType.CANCEL_SECONDARY:
        return Colors.VERMELHO_GOV;
    }
  };

  return (
    <Button
      type={isSubmit ? 'submit' : 'button'}
      variant={setType()}
      disabled={isDisabled}
      sx={{
        alignSelf: 'center',
        backgroundColor: setBackground(),
        color: setTextColor(),
      }}
      fullWidth
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
