import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { PageTitle } from 'components/Shared/PageTitle';
import DataConfirmation from 'components/Shared/DataConfirmation';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import AlertModal from 'components/Shared/AlertModal';

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

interface Step6Props {
  onNext: (answers: AnswersType) => void;
}

const beneficiariesList = [
  { name: 'Beatriz Rodrigues da Silva', cpf: '555.091.067-75' },
  { name: 'Bruno Souza Lima', cpf: '555.091.067-75' },
  { name: 'Carlos Marinho Duarte', cpf: '555.091.067-75' },
  { name: 'Cecília dos Santos Albuquerque', cpf: '555.091.067-75' },
  { name: 'Cíntia Vieira da Costa', cpf: '555.091.067-75' },
];

const Step6: React.FC<Step6Props> = ({ onNext }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSave = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(ROUTES.INCLUDE_CIFA_2026);
  };

  return (
    <>
      <PageTitle
        title='Confirmação dos Dados'
        subtitle='Confira todas as informações que foram preenchidas, e altere-as caso necessário.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <DataConfirmation
          title='Assistência Técnica e Extensão Rural - Município'
          data={[
            {
              label:
                'Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada exclusivamente pelo Município?',
              value: '765',
            },
            {
              label:
                'O Município realizou parceria com uma instituição executora de Assistência Técnica e Extensão Rural - ATER em 2025?',
              value: 'Sim',
            },
          ]}
          atas={[
            {
              titlePDF: 'Ata do CMDRS',
              label: 'ata-CMDRS',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
          beneficiaries={beneficiariesList}
          titleBeneficiaries='Lista de Beneficiários - Atendidos Exclusivamente pelo Município'
        />

        <DataConfirmation
          title='Assistência Técnica e Extensão Rural - EMPAER MT'
          data={[
            {
              label: 'O Município realizou parceria com a EMPAER MT em 2025?',
              value: 'Sim',
            },
            {
              label:
                'Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no Município?',
              value: '35',
            },
          ]}
          atas={[
            {
              titlePDF: 'Documento de Formalização da Parceria',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
            {
              titlePDF:
                'Relatório Técnico Consolidado - Ata do CMDRS + Relatório Detalhado',
              label: 'relatório-consolidado',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
          beneficiaries={beneficiariesList}
          titleBeneficiaries='Lista de Beneficiários - Atendidos em parceria com a EMPAER MT'
        />

        <DataConfirmation
          title='Assistência Técnica e Extensão Rural - Instituições de Ensino'
          data={[
            {
              label: 'O Município realizou parceria com a EMPAER MT em 2025?',
              value: 'Sim',
            },
            {
              label:
                'Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no Município?',
              value: '35',
            },
          ]}
          atas={[
            {
              titlePDF: 'Documento de Formalização da Parceria',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
            {
              titlePDF:
                'Relatório Técnico Consolidado - Ata do CMDRS + Relatório Detalhado',
              label: 'relatório-consolidado',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
          beneficiaries={beneficiariesList}
          titleBeneficiaries='Lista de Beneficiários - Atendidos em parceria com a EMPAER MT'
        />

        <DataConfirmation
          title='Assistência Técnica e Extensão Rural - Organizações ONGs'
          data={[
            {
              label: 'O Município realizou parceria com a EMPAER MT em 2025?',
              value: 'Sim',
            },
            {
              label:
                'Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no Município?',
              value: '35',
            },
          ]}
          atas={[
            {
              titlePDF: 'Documento de Formalização da Parceria',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
            {
              titlePDF:
                'Relatório Técnico Consolidado - Ata do CMDRS + Relatório Detalhado',
              label: 'relatório-consolidado',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
          beneficiaries={beneficiariesList}
          titleBeneficiaries='Lista de Beneficiários - Atendidos em parceria com a EMPAER MT'
        />

        <DataConfirmation
          title='Assistência Técnica e Extensão Rural - Instituições Privadas/ Outros'
          data={[
            {
              label: 'O Município realizou parceria com a EMPAER MT em 2025?',
              value: 'Sim',
            },
            {
              label:
                'Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no Município?',
              value: '35',
            },
          ]}
          atas={[
            {
              titlePDF: 'Documento de Formalização da Parceria',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
            {
              titlePDF:
                'Relatório Técnico Consolidado - Ata do CMDRS + Relatório Detalhado',
              label: 'relatório-consolidado',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
          beneficiaries={beneficiariesList}
          titleBeneficiaries='Lista de Beneficiários - Atendidos em parceria com a EMPAER MT'
        />

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' disabled>
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSave}
            //disabled={!isAdvanceEnabled}
          >
            Confirmar e salvar
          </Button>
        </Stack>
        <Stack />

        <AlertModal
          open={isModalOpen}
          title='Etapa Concluída com Sucesso'
          message='O preenchimento dos dados dessa etapa foi concluído com sucesso. Continue realizando o preenchimento das próximas etapas para concluir o cadastro do Município no CIFA 2026.'
          buttonLabel='Avançar'
          onClose={handleCloseModal}
        />
      </Stack>
    </>
  );
};

export default Step6;
