import React from 'react';

import { Divider } from '@mui/material';

import { Stack } from '@mui/system';
import { Header } from 'components/Home';
import { CustomLabel } from 'components/Shared';
import { CardMenu } from 'components/Shared/CardMenu';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';

const MenuCifa: React.FC = () => {
  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='Gerenciar CIFA' showBackButton={true} />

      <Stack mt='32px' paddingX='384px' spacing='1rem'>
        <CustomLabel
          text='Gerenciamento CIFA'
          labelStyle={{ fontFamily: 'Roboto', fontSize: '22px', fontWeight: 500, letterSpacing: '0.15px', lineHeight: '35.2px', textAlign: 'left' }}
        />

        <Divider />

        <CardMenu title='Ano de 2025' description='Visualize as informações do questionário CIFA 2025.' pathTo={ROUTES.CIFA2025} />
        <CardMenu title='Ano de 2026' description='Visualize as informações do questionário CIFA 2026.' pathTo={ROUTES.CIFA2026} />
      </Stack>
    </MenuProvider>
  );
};

export default MenuCifa;
