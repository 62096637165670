import { Box, Button, FormControl, FormLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import React from 'react';

interface OrdenarModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const modalStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px 8px 0 0',
  p: '0 360px',
  pt: 4,
  pb: 4,
  display: 'flex',
  flexDirection: 'column',
};

const OrdenarModal: React.FC<OrdenarModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2' marginBottom='16px'>
          Ordenar
        </Typography>
        <FormControl fullWidth>
          {' '}
          <FormLabel id='ordenar-label'>Ordenar</FormLabel>
          <Select defaultValue='Nome'>
            <MenuItem value='Nome'>Pelo Nome (ordem alfabética - De A a Z)</MenuItem>
            <MenuItem value='CPF'>Pelo CPF</MenuItem>
          </Select>
        </FormControl>

        <Stack direction='row' justifyContent='space-between' marginTop='24px' sx={{ width: '100%', maxWidth: '400px' }}>
          <Button variant='contained' color='primary' onClick={onClose} sx={{ textTransform: 'none' }}>
            Confirmar
          </Button>
          <Button variant='outlined' color='primary' onClick={onClose} sx={{ textTransform: 'none' }}>
            Voltar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default OrdenarModal;
