import React, { useState } from 'react';
import TopLinksBar from './TopLinksBar';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Menu, MenuItem, Typography } from '@mui/material';
import logoMtGovBrIcon from '../../assets/icons/logo-mtgovbr-icon.svg';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { CustomSecondaryAppBar, LogoContainer, RightContainer } from './TopNavBar.styles';
import { InitialsAvatar } from 'components/Shared';
import { getLogoutMtLogin } from 'services/auth/apiService';
import { useAuth } from 'contexts/auth/AuthContext';

const textStyle = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  color: '#00000099',
};

const TopNavBar: React.FC = () => {
  const { user } = useAuth();
  const userRegistration = '123456789';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setAnchorEl(null);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleLogoutConfirm = () => {
    getLogoutMtLogin();
  };

  return (
    <>
      <TopLinksBar />
      <CustomSecondaryAppBar>
        <LogoContainer>
          <img src={logoMtGovBrIcon} alt='Logo' style={{ height: '40px' }} />
        </LogoContainer>
        <RightContainer>
          <Badge badgeContent={1} color='primary'>
            <NotificationsIcon color='action' fontSize='large' />
          </Badge>
          <InitialsAvatar name={user?.name ? user.name : ''} onClick={handleMenuOpen} style={{ cursor: 'pointer' }} variant='circular' />

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                width: 'auto',
              },
            }}
          >
            <MenuItem
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  textAlign: 'left',
                  color: '#000000DE',
                }}
              >
                {user?.name ? user.name : ''}
              </Typography>
              <Typography sx={textStyle}>Matrícula: {userRegistration}</Typography>
              <Typography sx={textStyle}>Perfil: {user?.profile.name}</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogoutClick} sx={{ padding: '0px' }}>
              <Button variant='text'>
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    letterSpacing: '0.46px',
                    textAlign: 'left',
                    color: '#000000DE',
                  }}
                >
                  Sair
                </Typography>
              </Button>
            </MenuItem>
          </Menu>
        </RightContainer>
      </CustomSecondaryAppBar>

      <Dialog open={isDialogOpen} fullWidth maxWidth='md' sx={{ width: '100%' }} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
              marginTop: '20px',
            }}
          >
            Sair do sistema
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#000000DE',
            }}
          >
            Deseja sair e realizar o logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'left', marginLeft: '10px', marginBottom: '10px' }}>
          <Button onClick={handleCloseDialog}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.4px',
                textAlign: 'left',
                color: '#0048B6',
              }}
            >
              VOLTAR
            </Typography>
          </Button>

          <Button onClick={handleLogoutConfirm}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.4px',
                color: '#D32F2F',
              }}
            >
              sair
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopNavBar;
