/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import type { StepIconProps } from '@mui/material';
import { Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from '@mui/material';

import { Colors } from 'theme';

interface HorizontalStepperProps {
  activeStep: number;
}

export const HorizontalStepper: React.FC<HorizontalStepperProps> = ({ activeStep }: HorizontalStepperProps) => {
  const steps = [
    'Assistência Técnica e Extensão Rural',
    'Alimentação Escolar (PNAE Federal)',
    'Alimentação Escolar (Governo do Estado)',
    'Alimentação Escolar (Prefeitura)',
  ];

  const centralizeActiveStep = () => {
    const stepperContainer = document.getElementById('stepper-container');
    const stepperSteps = stepperContainer!.querySelectorAll('.MuiStep-root');
    const activeStepElement = stepperSteps[activeStep];

    if (activeStepElement instanceof HTMLElement) {
      const containerWidth = stepperContainer!.offsetWidth;
      const stepWidth = activeStepElement.offsetWidth;
      const offset = containerWidth / 2 - stepWidth / 2;
      stepperContainer!.scrollLeft = activeStepElement.offsetLeft - offset;
    }
  };

  useEffect(() => {
    centralizeActiveStep();
  }, []);

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#2E7D32',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#2E7D32',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#0048B6',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#0048B6',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }}>
        {completed ? <CheckCircleIcon color='success' /> : !completed && !active ? <CircleIcon /> : <RadioButtonCheckedIcon />}
      </QontoStepIconRoot>
    );
  }

  return (
    <Stack paddingX={{ xs: '0rem' }}>
      <Stack
        id='stepper-container'
        sx={{
          maxWidth: '100vw',
          backgroundColor: '#FFFFFF',
          overflow: 'auto',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
        paddingX={{ xs: '1rem' }}
        paddingY='1.75rem'
        justifyContent='center'
        alignItems={{ md: 'center' }}
      >
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((step, index) => (
            <Step
              key={index}
              sx={{
                whiteSpace: {
                  xs: 'nowrap',
                  md: 'normal',
                },
              }}
            >
              <StepLabel StepIconComponent={QontoStepIcon} sx={{ alignItems: 'center' }}>
                <Typography fontWeight={'400'} fontSize={'0.75rem'} sx={{ color: '#797979' }}>
                  PASSO {index + 1}
                </Typography>

                <Typography fontWeight={'600'} fontSize={'0.8rem'}>
                  {step}
                </Typography>

                {activeStep > index ? (
                  <Typography fontSize={'0.75rem'} color='green'>
                    preenchido
                  </Typography>
                ) : (
                  <></>
                )}

                {activeStep === index && (
                  <Typography fontSize={'0.75rem'} color={Colors.AZUL_GOV}>
                    a preencher
                  </Typography>
                )}

                {activeStep < index && (
                  <Typography fontSize={'0.75rem'} color='gray'>
                    pendente
                  </Typography>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Stack>
  );
};
