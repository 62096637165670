import React from 'react';

import { Divider } from '@mui/material';

import { Stack } from '@mui/system';
import { Header } from 'components/Home';
import { CustomLabel } from 'components/Shared';
import { CardMenu } from 'components/Shared/CardMenu';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';

const ManageCifa2026: React.FC = () => {
  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='Gerenciar CIFA - 2026' showBackButton={true} />

      <Stack mt='32px' paddingX='384px' spacing='1rem'>
        <CustomLabel
          text='Gerenciar CIFA - 2026'
          labelStyle={{ fontFamily: 'Roboto', fontSize: '22px', fontWeight: 500, letterSpacing: '0.15px', lineHeight: '35.2px', textAlign: 'left' }}
        />

        <Divider />

        <CardMenu
          title='Cadastrar Município - 2026'
          description='Realize a Inclusão do Município no Cadastro do Índice Municipal de Agricultura Familiar.'
          pathTo={ROUTES.INCLUDE_CIFA_2026}
        />

        <Divider />

        <CardMenu
          title='Alterar Município - 2026'
          description='Atualize os dados do Município no Cadastro do Índice Municipal de Agricultura Familiar'
          pathTo={ROUTES.CIFA}
        />

        <Divider />

        <CardMenu
          title='Visualizar Cadastro do Município - 2026'
          description='Veja os dados do Município no Cadastro do Índice Municipal de Agricultura Familiar.'
          pathTo={ROUTES.CIFA}
        />
      </Stack>
    </MenuProvider>
  );
};

export default ManageCifa2026;
