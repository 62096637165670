import React from 'react';

import { Dialog, DialogContent, DialogTitle, Paper, Stack, Typography } from '@mui/material';
import type { ResponsiveStyleValue } from '@mui/system';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  icon?: JSX.Element;
  title?: string | JSX.Element;
  titleColor?: string;
  subTitle?: string | JSX.Element;
  directionButtons?: ResponsiveStyleValue<'column' | 'row' | 'row-reverse' | 'column-reverse'>;
  buttons?: JSX.Element[] | JSX.Element;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ open, onClose, icon, title, titleColor, subTitle, directionButtons = 'column', buttons, children }) => (
  <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth PaperComponent={Paper} PaperProps={{ sx: { borderRadius: '0.25rem' } }}>
    {icon && <Stack mt='1.5rem'>{icon}</Stack>}

    {title && (
      <DialogTitle color={titleColor} textAlign='center'>
        {title}
      </DialogTitle>
    )}
    {subTitle && (
      <Typography variant='body2' color='textSecondary' mt='0.5rem' textAlign='center'>
        {subTitle}
      </Typography>
    )}

    <DialogContent>
      <Stack mt='1rem'>{children}</Stack>

      {buttons && (
        <Stack direction={directionButtons} mt='1rem' spacing='1rem'>
          {buttons}
        </Stack>
      )}
    </DialogContent>
  </Dialog>
);
