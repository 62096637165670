import React, { useState } from 'react';
import { Header } from 'components/Home';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { StepperHorizontal } from 'components/Shared/StepperHorizontal';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

export interface InstitutionI {
  id: number;
  name: string;
}

type AnswersType = {
  question1: string | null;
  question2: string | null;
  question3: string | null;
  question4: string | null;
};

const TechAssist: React.FC = () => {
  const steps = [
    'ATER - Município',
    'ATER - EMPAER MT',
    'ATER - Instituições de Ensino',
    'ATER - Organizações ONGs',
    'ATER - Instituições Privadas/ Outros',
    'Confirmação dos Dados',
  ];
  const [activeStep, setActiveStep] = useState<number>(0);

  const [answers, setAnswers] = useState<AnswersType>({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
  });

  const handleNext = (newAnswers?: AnswersType) => {
    if (newAnswers) setAnswers(newAnswers);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <MenuProvider>
      <TopNavBar />
      <Header title='CIFA - Cadastrar Município - 2026' showBackButton={true} />
      <StepperHorizontal activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <Step1 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 1 && (
        <Step2 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 2 && (
        <Step3 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 3 && (
        <Step4 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 4 && (
        <Step5 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
      {activeStep === 5 && (
        <Step6 onNext={(newAnswers) => handleNext(newAnswers)} />
      )}
    </MenuProvider>
  );
};

export default TechAssist;
