/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Avatar, Button, Card, CardHeader, IconButton, Stack, TextField, Typography } from '@mui/material';

import { MunicipalPartner, MunicipalPartnerType } from 'api/answers/dtos/answers.dto';
import { postDocument } from 'api/documents/documents.api';
import { PostDocumentsPayload } from 'api/documents/dtos/documents.dto';
import { CustomLabel } from 'components/Shared';
import { ContainerForm } from 'components/Shared/ContainerForm';
import { SnackbarFeedback } from 'components/Shared/SnackbarFeedback';
import { InstitutionI } from 'pages/ServiceManagement/Cifa/2025/CifaCrud2025/CreateCifa2025';

export const InstitutionForm: FC<{ institution: InstitutionI; setInstituion: (value: MunicipalPartner) => void; data?: MunicipalPartner }> = ({
  institution,
  setInstituion,
  data,
}) => {
  const [numberOfBeneficiary, setNumberOfBeneficiary] = useState<number>(data?.numberOfBeneficiary || 0);

  const [formalizationSizeError, setFormalizationSizeError] = useState<boolean>(false);
  const [formalizationExtensionError, setFormalizationExtensionError] = useState<boolean>(false);
  const [formalizationFile, setFormalizationFile] = useState<File | null>(null);
  const [formalizationUuid, setFormalizationUuid] = useState<string>(data?.partnershipDocumentUuid || '');

  const [cmdrsSizeError, setCmdrsSizeError] = useState<boolean>(false);
  const [cmdrsExtensionError, setCmdrsExtensionError] = useState<boolean>(false);
  const [cmdrsFile, setCmdrsFile] = useState<File | null>(null);
  const [cmdrsUuid, setCmdrsUuid] = useState<string>(data?.partnershipDocumentUuid || '');

  const [errorMessage, setErrorMessage] = useState<string>();

  const submitDocument = async (documentName: string, file: File): Promise<boolean> => {
    try {
      const payload: PostDocumentsPayload = {
        documentInfo: {
          type: documentName,
        },
        file,
      };

      const response = await postDocument(payload);

      if (documentName === 'DOCUMENTO DE PARCEIRIA') {
        setFormalizationUuid(response.uuid);
        handleChange(numberOfBeneficiary, response.uuid, cmdrsUuid);
      } else {
        setCmdrsUuid(response.uuid);
        handleChange(numberOfBeneficiary, formalizationUuid, response.uuid);
      }
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const handleUploadFormalization = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined);

    const tamanhoMaximoEmBytes = 20971520;
    const extensoesAceitas = ['pdf'];

    const arquivoAnexo = event.target.files && event.target.files[0];
    const extensaoArquivo = arquivoAnexo?.name.slice(((arquivoAnexo!.name.lastIndexOf('.') - 1) >>> 0) + 2);

    if (arquivoAnexo && arquivoAnexo?.size > tamanhoMaximoEmBytes) {
      setFormalizationExtensionError(false);
      setFormalizationSizeError(true);
    } else if (extensaoArquivo && !extensoesAceitas.includes(extensaoArquivo)) {
      setFormalizationSizeError(false);
      setFormalizationExtensionError(true);
    } else if (arquivoAnexo) {
      const request = await submitDocument('DOCUMENTO DE PARCEIRIA', arquivoAnexo);

      if (!request) {
        setErrorMessage('Erro ao enviar o Documento de Formalização da Parceira. Tente novamente.');
        return;
      }

      setFormalizationSizeError(false);
      setFormalizationExtensionError(false);
      setFormalizationFile(arquivoAnexo);
    }
  };

  const handleUploadCMDRS = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const tamanhoMaximoEmBytes = 20971520;
    const extensoesAceitas = ['pdf'];

    const arquivoAnexo = event.target.files && event.target.files[0];
    const extensaoArquivo = arquivoAnexo?.name.slice(((arquivoAnexo!.name.lastIndexOf('.') - 1) >>> 0) + 2);

    if (arquivoAnexo && arquivoAnexo?.size > tamanhoMaximoEmBytes) {
      setCmdrsExtensionError(false);
      setCmdrsSizeError(true);
    } else if (extensaoArquivo && !extensoesAceitas.includes(extensaoArquivo)) {
      setCmdrsSizeError(false);
      setCmdrsExtensionError(true);
    } else if (arquivoAnexo) {
      const request = await submitDocument('ATA-CMDRS', arquivoAnexo);

      if (!request) {
        setErrorMessage('Erro ao enviar a Ata do CMDRS. Tente novamente.');
        return;
      }

      setCmdrsSizeError(false);
      setCmdrsExtensionError(false);
      setCmdrsFile(arquivoAnexo);
    }
  };

  const handleChange = (e?: any, uuidFormalization?: string, uuidCmdrs?: string) => {
    if (e?.target?.value) {
      setNumberOfBeneficiary(Number(e.target.value.replace(/\D/g, '')));
    } else {
      setNumberOfBeneficiary(e);
    }

    const partnersTypes = [
      MunicipalPartnerType.EMPAER_MT,
      MunicipalPartnerType.EDUCATIONAL_INSTITUTION,
      MunicipalPartnerType.ORGANIZATION_INSTITUTION,
      MunicipalPartnerType.PRIVATE_INSTITUTION,
    ];

    const partialPayload: MunicipalPartner = {
      name: partnersTypes[institution.id - 1],
      numberOfBeneficiary: Number(e?.target?.value?.replace(/\D/g, '')) || numberOfBeneficiary,
      partnershipDocumentUuid: uuidFormalization || formalizationUuid,
      ataCmdrsDocumentUuid: uuidCmdrs || cmdrsUuid,
    };

    setInstituion(partialPayload);
  };

  useEffect(() => {
    if (data) {
      setInstituion(data);
    }
  }, []);

  return (
    <ContainerForm title={institution.name + ' - Dados da parceria com a Instituição (ATER)'}>
      <TextField
        id='beneficiarios-atendidos'
        label='Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no Município? (Opcional)'
        variant='outlined'
        value={numberOfBeneficiary}
        onChange={(e) => handleChange(e)}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          maxLength: 100,
        }}
      />

      <Stack spacing={1} alignItems='flex-start'>
        <Stack direction='row' spacing={1}>
          <CustomLabel
            text='Anexar Documento de Formalização da Parceira'
            labelStyle={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '16px', lineHeight: '22.88px', letterSpacing: '0.17px', textAlign: 'left' }}
          />
          <CustomLabel
            text='(Opcional)'
            labelStyle={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '22.88px',
              letterSpacing: '0.17px',
              textAlign: 'left',
              color: '#999999',
            }}
          />
        </Stack>

        <CustomLabel
          text='Anexe o Documento de Formalização da Parceria.'
          labelStyle={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
            textAlign: 'left',
            color: '#999999',
          }}
        />

        <Stack>
          <Stack paddingTop='1rem'>
            <input type='file' style={{ display: 'none' }} id={institution.id + '1'} onChange={handleUploadFormalization} accept='.pdf' />
            <label htmlFor={institution.id + '1'} style={{ marginLeft: 0 }}>
              <Button variant='outlined' component='span' startIcon={<AttachFileIcon />}>
                ANEXAR ARQUIVO
              </Button>
            </label>
          </Stack>

          <CustomLabel
            text='Arquivos PDF (máximo de 20MB)'
            labelStyle={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20.02px',
              letterSpacing: '0.17px',
              textAlign: 'left',
              color: '#999999',
            }}
          />
        </Stack>

        {formalizationFile && (
          <Card sx={{ width: { md: '45%' } }}>
            <CardHeader
              avatar={
                <Avatar sx={{ backgroundColor: 'transparent' }}>
                  <InsertDriveFileOutlinedIcon color='action' />
                </Avatar>
              }
              title={<Typography gutterBottom>{formalizationFile?.name || ''}</Typography>}
              subheader={
                <Typography color='text.secondary' fontSize='0.875rem' gutterBottom>
                  {formalizationFile?.size && (formalizationFile.size / 1024 / 1024).toFixed(2) + ' MB'}
                </Typography>
              }
              action={
                <IconButton aria-label='check' style={{ cursor: 'default' }}>
                  <CheckCircleIcon color='success' />
                </IconButton>
              }
            />
          </Card>
        )}

        {formalizationSizeError && <Typography color='error'>O arquivo deve ter no máximo 20 MB.</Typography>}
        {formalizationExtensionError && <Typography color='error'>O arquivo deve ser no formato PNG, JPG ou PDF.</Typography>}
      </Stack>

      <Stack spacing={1} alignItems='flex-start'>
        <CustomLabel
          text='Anexar Ata do CMDRS:'
          labelStyle={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '16px', lineHeight: '22.88px', letterSpacing: '0.17px', textAlign: 'left' }}
        />

        <CustomLabel
          text='Anexe a ata do CMDRS contendo a aprovação do relatório técnico de atendimentos de ATER praticados pelo Município em 2024. Para as categorias ATER (apurado em 2025), somente será solicitada a ata do CMDRS que aprova as informações prestadas na SEIAF-MT.'
          labelStyle={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
            textAlign: 'left',
            color: '#999999',
          }}
        />

        <Stack>
          <Stack paddingTop='1rem'>
            <input type='file' style={{ display: 'none' }} id={institution.id + '2'} onChange={handleUploadCMDRS} accept='.pdf' />
            <label htmlFor={institution.id + '2'} style={{ marginLeft: 0 }}>
              <Button variant='outlined' component='span' startIcon={<AttachFileIcon />}>
                ANEXAR ARQUIVO
              </Button>
            </label>
          </Stack>

          <CustomLabel
            text='Arquivos PDF (máximo de 20MB)'
            labelStyle={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20.02px',
              letterSpacing: '0.17px',
              textAlign: 'left',
              color: '#999999',
            }}
          />
        </Stack>

        {cmdrsFile && (
          <Card sx={{ width: { md: '45%' } }}>
            <CardHeader
              avatar={
                <Avatar sx={{ backgroundColor: 'transparent' }}>
                  <InsertDriveFileOutlinedIcon color='action' />
                </Avatar>
              }
              title={<Typography gutterBottom>{cmdrsFile?.name || ''}</Typography>}
              subheader={
                <Typography color='text.secondary' fontSize='0.875rem' gutterBottom>
                  {cmdrsFile?.size && (cmdrsFile.size / 1024 / 1024).toFixed(2) + ' MB'}
                </Typography>
              }
              action={
                <IconButton aria-label='check' style={{ cursor: 'default' }}>
                  <CheckCircleIcon color='success' />
                </IconButton>
              }
            />
          </Card>
        )}

        {cmdrsSizeError && <Typography color='error'>O arquivo deve ter no máximo 20 MB.</Typography>}
        {cmdrsExtensionError && <Typography color='error'>O arquivo deve ser no formato PNG, JPG ou PDF.</Typography>}
      </Stack>

      {errorMessage && <SnackbarFeedback isOpen type='error' message={errorMessage} />}
    </ContainerForm>
  );
};
