import axios from 'axios';
import { BASE_API_URL } from 'common/const.common';
import { IGetByIdentificationUser } from '../interfaces/user.interface';

const MODULE_URL = BASE_API_URL + '/users';

export const getByIdentificationUser = async (identification: string): Promise<IGetByIdentificationUser> => {
  const response = await axios.get<any>(`${MODULE_URL}/identification/${identification}`);

  localStorage.setItem('userAccessUuid', response?.data?.uuid);
  localStorage.setItem('institutionUuid', response?.data?.institution?.uuid);

  return response.data as IGetByIdentificationUser;
};
