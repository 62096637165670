import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Stack, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface GlobalModalProps {
  open: boolean;
  title: string;
  message: string;
  buttonLabel?: string;
  onClose: () => void;
  icon?: React.ReactNode;
}

const AlertModal: React.FC<GlobalModalProps> = ({ open, title, message, buttonLabel = 'Fechar', onClose, icon }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth sx={{ '.MuiPaper-root': { padding: '1.5rem' } }}>
      <DialogContent>
        <Stack alignItems='center' spacing={2}>
          <Box
            sx={{
              backgroundColor: '#0048B6',
              borderRadius: '50%',
              padding: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon || <CheckCircleIcon sx={{ color: '#FFFFFF', fontSize: '2.5rem' }} />}
          </Box>
          <Typography variant='h6' align='center' fontWeight='bold'>
            {title}
          </Typography>
          <Typography variant='body1' align='center'>
            {message}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onClose} variant='text' color='primary'>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
