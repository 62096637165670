import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Stack, TextField, Typography } from '@mui/material';

import { getAnswersSchoolFeeding, putAnswers } from 'api/answers/answers.api';
import {
  FormName,
  GetAnswersPayload,
  PostAnswersPayload,
} from 'api/answers/dtos/answers.dto';
import { ListProducts } from 'components/Cifa/ListProducts';
import { Header } from 'components/Home';
import { ContainerForm } from 'components/Shared/ContainerForm';
import { FormButton, FormButtonType } from 'components/Shared/FormButton';
import { HorizontalStepper } from 'components/Shared/HorizontalStepper';
import { Modal } from 'components/Shared/Modal';
import { PageTitle } from 'components/Shared/PageTitle';
import { SnackbarFeedback } from 'components/Shared/SnackbarFeedback';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';

export interface Product {
  name: string;
  identification: string;
  value: string;
}

const EditStateSchoolFeeding2025: React.FC = () => {
  const navigate = useNavigate();

  const [provided, setProvided] = useState<number>(0);
  const [applied, setApplied] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const [addModal, setAddModal] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>();
  const [identification, setidentification] = useState<string>();
  const [value, setValue] = useState<string>();
  const [cnpjProducts, setCnpjProducts] = useState<Product[]>([]);
  const [cpfProducts, setCpfProducts] = useState<Product[]>([]);
  const [id, setId] = useState<string>();

  const handleCnpjProduct = () => {
    if (name && identification && value) {
      const product: Product = {
        name,
        identification,
        value,
      };

      setCnpjProducts((prevProducts) => {
        const existingProductIndex = prevProducts.findIndex(
          (p) => p.identification === identification,
        );
        if (existingProductIndex !== -1) {
          const updatedProducts = [...prevProducts];
          updatedProducts[existingProductIndex] = product;
          return updatedProducts;
        } else {
          return [...prevProducts, product];
        }
      });

      setidentification(undefined);
      setValue(undefined);
      setName(undefined);
      setAddModal(undefined);
    }
  };

  const handleCpfProduct = () => {
    if (name && identification && value) {
      const product: Product = {
        name,
        identification,
        value,
      };

      setCpfProducts((prevProducts) => {
        const existingProductIndex = prevProducts.findIndex(
          (p) => p.identification === identification,
        );
        if (existingProductIndex !== -1) {
          const updatedProducts = [...prevProducts];
          updatedProducts[existingProductIndex] = product;
          return updatedProducts;
        } else {
          return [...prevProducts, product];
        }
      });

      setidentification(undefined);
      setValue(undefined);
      setName(undefined);
      setAddModal(undefined);
    }
  };

  const handleDeleteCnpjProduct = (id: string) => {
    setCnpjProducts((prevProducts) =>
      prevProducts.filter((p) => p.identification !== id),
    );
    setidentification(undefined);
    setValue(undefined);
    setName(undefined);
  };

  const handleDeleteCpfProduct = (id: string) => {
    setCpfProducts((prevProducts) =>
      prevProducts.filter((p) => p.identification !== id),
    );
    setidentification(undefined);
    setValue(undefined);
    setName(undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const userAccessUuid = localStorage.getItem('userAccessUuid') || '';
    const institutionUuid = localStorage.getItem('institutionUuid') || '';

    const payload: PostAnswersPayload = {
      userAccessUuid,
      institutionUuid,
      formName: FormName.ALIMENTACAO_ESCOLAR_ESTADUAL,
      answer: {
        valueProvidedByPnae: provided,
        valueAppliedByPnaeResource: applied,
        productAquiredFromCnpjByPnaeResourceList: cnpjProducts,
        productAquiredFromCpfByPnaeResourceList: cpfProducts,
      },
      referencedYear: 2025,
    };

    if (id) {
      try {
        await putAnswers(payload, id);
        navigate(ROUTES.EDIT_MUNICIPAL_SCHOOL_FEEDING_2025);
      } catch (error) {
        setErrorMessage(
          'Erro ao atualizar os dados da Alimentação Escolar (PNAE Estadual).',
        );
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage(
        'Erro ao atualizar os dados da Alimentação Escolar (PNAE Estadual). ',
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const institutitonUuid = localStorage.getItem('institutionUuid') || '';
      const userAccessUuid = localStorage.getItem('userAccessUuid') || '';

      const payload: GetAnswersPayload = {
        institutitonUuid,
        userAccessUuid,
        referencedYear: 2025,
      };

      try {
        const response = await getAnswersSchoolFeeding(payload);

        const form = response?.data.find(
          (form) => form.formName === 'ALIMENTAÇÃO ESCOLAR ESTADUAL',
        );
        if (form) {
          setId(form?.uuid);
          setProvided(form?.answer?.valueProvidedByPnae);
          setApplied(form?.answer?.valueAppliedByPnaeResource);
          setCnpjProducts(
            form?.answer?.productAquiredFromCnpjByPnaeResourceList,
          );
          setCpfProducts(form?.answer?.productAquiredFromCpfByPnaeResourceList);
        }
      } catch (error) {
        setErrorMessage(
          'Erro ao recuperar os dados da Aba Alimentação Escolar (Estadual).',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='CIFA - Editar Município - 2025' showBackButton={true} />

      <HorizontalStepper activeStep={2} />

      <PageTitle
        title='Alimentação Escolar (PNAE Estadual)'
        subtitle='Informe corretamente os dados sobre a aquisição de Alimentação Escolar através do PNAE Estadual no Município.'
      />

      <Stack marginX='4rem'>
        <ContainerForm title='Valores para Aquisição de Alimentação Escolar - Em 2024'>
          <Stack direction='row' justifyContent='space-between' gap='1rem'>
            <TextField
              id='disponibilizado'
              label='Valor disponibilizado pelo PNAE Estadual em R$:'
              variant='outlined'
              value={provided}
              onChange={(e) =>
                setProvided(Number(e.target.value.replace(/\D/g, '')))
              }
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              id='aplicado'
              label='Valores totais aplicados pelo município na Alimentação Escolar oriundos de todo e qualquer fornecedor no ano de 2024 '
              variant='outlined'
              value={applied}
              onChange={(e) =>
                setApplied(Number(e.target.value.replace(/\D/g, '')))
              }
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ flex: 1 }}
            />
          </Stack>
        </ContainerForm>

        <ContainerForm title='Produtos adquiridos via CNPJ (com recurso do PNAE Estadual) - Valores Aplicados na Aquisição de Produtos (Opcional)'>
          {cnpjProducts.length === 0 && (
            <Alert
              severity='info'
              sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
            >
              <Typography
                variant='body2'
                fontSize='14px'
                textAlign='left'
                lineHeight={1.5}
              >
                Você ainda não realizou a inclusão de nenhuma Aquisição de
                Produto via CNPJ. Clique no botão abaixo ”Adicionar Aquisição”
                para incluir uma Aquisição de Produto.
              </Typography>
            </Alert>
          )}

          <FormButton
            type={FormButtonType.PRIMARY}
            title='+ Adicionar Aquisição'
            onClick={() => setAddModal('CNPJ')}
          />

          {cnpjProducts.length > 0 && (
            <ListProducts
              products={cnpjProducts}
              documentType={'CNPJ'}
              edit={(value) => {
                setName(value.name), setidentification(value.identification);
                setValue(value.value);
                setAddModal('CNPJ');
              }}
              remove={(value) => handleDeleteCnpjProduct(value.identification)}
            />
          )}
        </ContainerForm>

        <ContainerForm title='Produtos adquiridos via CPF (com recurso do PNAE Estadual) - Valores Aplicados na Aquisição de Produtos (Opcional)'>
          {cpfProducts.length === 0 && (
            <Alert
              severity='info'
              sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
            >
              <Typography
                variant='body2'
                fontSize='14px'
                textAlign='left'
                lineHeight={1.5}
              >
                Você ainda não realizou a inclusão de nenhuma Aquisição de
                Produto via CPF. Clique no botão abaixo ”Adicionar Aquisição”
                para incluir uma Aquisição de Produto.
              </Typography>
            </Alert>
          )}

          <FormButton
            type={FormButtonType.PRIMARY}
            title='+ Adicionar Aquisição'
            onClick={() => setAddModal('CPF')}
          />

          {cpfProducts.length > 0 && (
            <ListProducts
              products={cpfProducts}
              documentType={'CPF'}
              edit={(value) => {
                setName(value.name), setidentification(value.identification);
                setValue(value.value);
                setAddModal('CPF');
              }}
              remove={(value) => handleDeleteCpfProduct(value.identification)}
            />
          )}
        </ContainerForm>

        <Stack
          direction={{ md: 'row-reverse' }}
          marginY='2rem'
          sx={{ gap: '1rem' }}
        >
          <FormButton
            title='Avançar'
            type={FormButtonType.PRIMARY}
            onClick={handleSubmit}
            isDisabled={loading}
          />
          <FormButton
            title='Voltar'
            type={FormButtonType.SECONDARY}
            onClick={() => navigate(ROUTES.EDIT_FEDERAL_SCHOOL_FEEDING_2025)}
          />
        </Stack>

        <Modal
          open={!!addModal}
          onClose={() => setAddModal(undefined)}
          title={`Adicionar Aquisição via ${addModal} - Produtos adquiridos com recurso do PNAE Estadual`}
          buttons={[
            <FormButton
              key={0}
              type={FormButtonType.PRIMARY}
              title='Confirmar'
              onClick={() =>
                addModal === 'CNPJ' ? handleCnpjProduct() : handleCpfProduct()
              }
            />,
            <FormButton
              key={1}
              type={FormButtonType.SECONDARY}
              title='Voltar'
              onClick={() => setAddModal(undefined)}
            />,
          ]}
          directionButtons='row'
        >
          <>
            <TextField
              id='nome'
              label='Nome/Razão social'
              variant='outlined'
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Stack
              direction='row'
              justifyContent='space-between'
              gap='1rem'
              mt='1rem'
            >
              <TextField
                id='documento'
                label={addModal}
                variant='outlined'
                value={identification}
                onChange={(e) =>
                  setidentification(e.target.value.replace(/\D/g, ''))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ flex: 1 }}
              />
              <TextField
                id='valor'
                label='Valor'
                variant='outlined'
                value={value}
                onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ flex: 1 }}
              />
            </Stack>
          </>
        </Modal>

        {errorMessage && (
          <SnackbarFeedback isOpen type='error' message={errorMessage} />
        )}
      </Stack>
    </MenuProvider>
  );
};

export default EditStateSchoolFeeding2025;
