import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import AlertModal from 'components/Shared/AlertModal';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'components/Shared/PageTitle';
import DataConfirmation from 'components/Shared/DataConfirmation';

interface Step2Props {
  answers: {
    question1: string | null;
    question2: string | null;
    question3: string | null;
    question4: string | null;
  };
  onBack: () => void;
}

const Step2: React.FC<Step2Props> = ({ answers, onBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(ROUTES.INCLUDE_CIFA_2026);
  };

  return (
    <>
      <PageTitle
        title='Confirmação dos Dados'
        subtitle='Confira todas as informações que foram preenchidas, e altere-as caso necessário.'
      />

      <Stack marginX='4rem' spacing='2rem'>
        <DataConfirmation
          title='PMAF - Plano de Agricultura Familiar'
          data={[
            {
              label:
                'O Município elaborou o Plano Municipal de Agricultura Familiar - PMAF?',
              value: answers.question1 || 'Não informado',
            },
            {
              label: 'O PMAF foi validado pela SEAF MT?',
              value: answers.question2 || 'Não informado',
            },
          ]}
          atas={[
            {
              titlePDF: 'Ato Normativo',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
        />
        <DataConfirmation
          title='CMDRS - Conselho Municipal de Desenvolvimento Rural Sustentável'
          data={[
            {
              label:
                'O Município possui o CMDRS constituído via Lei municipal?',
              value: answers.question3 || 'Não informado',
            },
          ]}
          atas={[
            {
              titlePDF:
                'Atas de reunião plenária do CMDRS em 2025, aprovadas e assinadas pelos(as) conselheiros(as) presentes e pelo(a) presidente do CMDRS',
              label: 'documento-parceria-1',
              fileName: 'Arquivo PDF',
              fileSize: '899 KB',
              onView: () => console.log('Visualizar documento-parceria-1'),
            },
          ]}
        />
        <DataConfirmation
          title='Formulário Prefeitura'
          data={[
            {
              label: 'O Município preencheu o formulário Prefeitura?',
              value: answers.question4 || 'Não informado',
            },
          ]}
        />

        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Button variant='text' color='primary' onClick={onBack}>
            Voltar
          </Button>
          <Button variant='contained' color='primary' onClick={handleSave}>
            Confirmar e Salvar
          </Button>
        </Stack>

        <AlertModal
          open={isModalOpen}
          title='Etapa Concluída com Sucesso'
          message='O preenchimento dos dados dessa etapa foi concluído com sucesso. Continue realizando o preenchimento das próximas etapas para concluir o cadastro do Município no CIFA 2026.'
          buttonLabel='Avançar'
          onClose={handleCloseModal}
        />
      </Stack>
    </>
  );
};

export default Step2;
