import type { ReactNode } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import { Colors } from 'theme';

export interface OptionaFunctionInterface {
  icon: JSX.Element;
  title: string;
  onClick: () => void;
}

interface ContainerFormProps {
  title: string;
  subtitle?: string;
  optionaFunction?: OptionaFunctionInterface;
  children: ReactNode;
}

export const ContainerForm = ({ title, subtitle, optionaFunction, children }: ContainerFormProps): JSX.Element => (
  <Stack sx={{ backgroundColor: Colors.BRANCO_GOV }} marginTop='1rem' padding='1rem' spacing='1rem'>
    <Stack>
      <Typography variant='h6' component='h6' fontSize='1.25rem' fontWeight='500' display='flex' justifyContent='space-between'>
        {title}

        {optionaFunction && (
          <Button variant='text' startIcon={optionaFunction.icon} onClick={optionaFunction.onClick}>
            {optionaFunction.title}
          </Button>
        )}
      </Typography>

      {subtitle && (
        <Typography variant='body2' marginTop='0.5rem' color='textSecondary'>
          {subtitle}
        </Typography>
      )}
    </Stack>

    {children}
  </Stack>
);
