import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const modalStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px 8px 0 0',
  p: '0 360px',
  pt: 4,
  pb: 4,
  display: 'flex',
  flexDirection: 'column',
};

const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2' marginBottom='16px'>
          Filtrar
        </Typography>
        <Stack direction='row' spacing={2} marginBottom='16px'>
          <TextField label='Nome/ Razão Social (Opcional)' variant='outlined' defaultValue='Beatriz Rodrigues da Silva' fullWidth />
          <TextField label='CPF (Opcional)' variant='outlined' defaultValue='555.091.067-75' fullWidth />
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom='16px'>
          <Button
            onClick={() => console.log('Limpar filtros')}
            sx={{
              color: '#007BFF',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Limpar
          </Button>
        </Stack>

        <Stack direction='row' justifyContent='space-between' marginTop='16px'>
          <Button
            variant='contained'
            color='primary'
            onClick={onClose}
            sx={{
              textTransform: 'none',
              width: '120px',
            }}
          >
            Confirmar
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={onClose}
            sx={{
              textTransform: 'none',
              width: '120px',
            }}
          >
            Voltar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FilterModal;
