const URL_PROJETO = getBaseUrl();
const CI_PROJECT_NAME = process.env.REACT_APP_CI_PROJECT_NAME ?? '';
const URL_MT_LOGIN = process.env.REACT_APP_URL_MT_LOGIN ?? '';

export const CONFIG = {
  grant_type: 'authorization_code',
  client_id: CI_PROJECT_NAME,
  redirect_uri: `${URL_PROJETO}/valida-login`,

  url_token: `${URL_MT_LOGIN}/auth/realms/mt-realm/protocol/openid-connect/token`,
  url_userInfo: `${URL_MT_LOGIN}/auth/realms/mt-realm/protocol/openid-connect/userinfo`,
  url_login: `${URL_MT_LOGIN}/auth/realms/mt-realm/protocol/openid-connect/auth?client_id=${CI_PROJECT_NAME}&redirect_uri=${URL_PROJETO}/valida-login&response_type=code`,
  url_logout: `${URL_MT_LOGIN}/auth/realms/mt-realm/protocol/openid-connect/logout?client_id=${CI_PROJECT_NAME}&redirect_uri=${URL_PROJETO}&response_type=code`,
};

function getBaseUrl(): string {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}

export const getURLMTLogin = (): string => {
  return CONFIG.url_login;
};

export const getURLMTLogout = (): string => {
  return CONFIG.url_logout;
};
