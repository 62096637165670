//TODO: Corrigir nomeclatura deste arquivo.
export interface User {
  uuid: string;
  name: string;
  cpf: string;
  email: string;
  hasAcceptedTerms: boolean;
  status: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export interface PartialUpdateUserData {
  name?: string;
  cpf?: string;
  email?: string;
  contactList?: {
    uuid?: string;
    type: 'CELULAR';
    value: string;
  }[];
  position?: string;
  occupation?: string;
  groupUuid?: string;
  profileUuid?: string;
  permissionUuid?: string;
  password?: string;
  statusUuidToAddList?: (string | undefined)[];
  statusUuidToDelList?: (string | undefined)[];
  hasAcceptedTerms?: boolean;
  status?: UserStatus;
}

export enum UserStatus {
  REGISTER_ACTIVE = 'ATIVO',
  REGISTER_BLOCKED = 'REGISTRO BLOQUEADO',
  REGISTER_ENABLED = 'INATIVO',
}

export interface UserListQuery {
  nome?: string;
  cpf?: string;
  groupUuid?: string;
  profileUuid?: string;
  permissionUuid?: string;
  status?: string;
  page?: string;
  limit: string;
  sort?: string;
}
