// components/Shared/DataConfirmation.tsx
import React, { useState } from 'react';
import { Stack, Typography, Button, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Colors } from 'theme';

interface Ata {
  titlePDF: string;
  label: string;
  fileName: string;
  fileSize: string;
  onView: () => void;
}

interface Beneficiary {
  name: string;
  cpf: string;
}

interface DataConfirmationProps {
  title?: string;
  subtitle?: string;
  data: {
    label: string;
    value: string;
    isEditable?: boolean;
    onAction?: () => void;
  }[];
  additionalText?: string;
  atas?: Ata[];
  beneficiaries?: Beneficiary[];
  titleBeneficiaries?: string;
}

const DataConfirmation: React.FC<DataConfirmationProps> = ({
  title,
  subtitle,
  data,
  atas,
  beneficiaries,
  titleBeneficiaries,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const totalPages = beneficiaries
    ? Math.ceil(beneficiaries.length / itemsPerPage)
    : 0;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Stack
      sx={{
        backgroundColor: Colors.BRANCO_GOV,
        borderRadius: '8px',
        marginTop: '1rem',
        padding: '1rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Title Section */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={toggleExpanded}
        sx={{
          cursor: 'pointer',
          padding: '0.5rem 1rem',
          borderBottom: isExpanded ? `1px solid ${Colors.CINZA_CLARO}` : 'none',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <IconButton size='small' sx={{ marginRight: '0.5rem' }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Typography variant='h6' fontWeight='500' fontSize='1.25rem'>
            {title}
          </Typography>
        </Stack>
        <Button
          variant='text'
          color='primary'
          onClick={(e) => e.stopPropagation()}
          sx={{ textTransform: 'none' }}
        >
          Editar
        </Button>
      </Stack>

      {/* Content Section */}
      {isExpanded && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
              padding: '1rem',
            }}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  flex: '1 1 calc(50% - 1rem)',
                  minWidth: '200px',
                }}
              >
                <Typography
                  variant='body1'
                  fontWeight={400}
                  sx={{ color: Colors.CINZA_ESCURO, marginBottom: '0.5rem' }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant='body2'
                  fontWeight={400}
                  sx={{ color: Colors.CINZA_MEDIO }}
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* ATAS Section */}
          {atas && (
            <Stack spacing={2} sx={{ marginTop: '1rem' }}>
              {atas.map((ata, index) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.CINZA_MEDIO,
                        fontWeight: '700',
                        marginTop: '1rem',
                        marginBottom: '0.5rem',
                      }}
                    >
                      {ata.titlePDF}
                    </Typography>
                    <Button variant='text' color='primary'>
                      Visualizar
                    </Button>
                  </Box>
                  <Stack
                    key={index}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    padding='0.5rem'
                    sx={{
                      backgroundColor: Colors.CINZA_CLARO,
                      borderRadius: '4px',
                    }}
                  >
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={400}
                        sx={{ color: Colors.CINZA_MEDIO }}
                      >
                        {ata.label}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: Colors.CINZA_ESCURO }}
                      >
                        {ata.fileName} ({ata.fileSize})
                      </Typography>
                    </Box>
                  </Stack>
                </>
              ))}

              {/* Beneficiaries Section */}
              {beneficiaries && (
                <>
                  <Typography
                    variant='h6'
                    sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
                  >
                    {titleBeneficiaries}
                  </Typography>
                  {beneficiaries
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage,
                    )
                    .map((beneficiary, index) => (
                      <Stack
                        key={index}
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        padding='1rem'
                        border='1px solid #ddd'
                        borderRadius='8px'
                      >
                        <Box>
                          <Typography variant='body1'>
                            {beneficiary.name}
                          </Typography>
                          <Typography variant='body2' color='textSecondary'>
                            CPF: {beneficiary.cpf}
                          </Typography>
                        </Box>
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() =>
                            console.log(
                              `Visualizar beneficiário: ${beneficiary.name}`,
                            )
                          }
                          sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                          }}
                        >
                          Visualizar
                        </Button>
                      </Stack>
                    ))}

                  {/* Pagination */}
                  <Stack
                    direction='row'
                    justifyContent='center'
                    spacing={2}
                    marginTop='1rem'
                  >
                    {[...Array(totalPages)].map((_, index) => (
                      <Button
                        key={index}
                        variant={
                          index + 1 === currentPage ? 'contained' : 'outlined'
                        }
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default DataConfirmation;
