import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { Alert, Button, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';

import { getAnswers } from 'api/answers/answers.api';
import { GetAnswersPayload } from 'api/answers/dtos/answers.dto';
import { Header } from 'components/Home';
import { ContainerForm } from 'components/Shared/ContainerForm';
import { FormButton, FormButtonType } from 'components/Shared/FormButton';
import { HorizontalStepper } from 'components/Shared/HorizontalStepper';
import { PageTitle } from 'components/Shared/PageTitle';
import { SnackbarFeedback } from 'components/Shared/SnackbarFeedback';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';
import { getDocument } from 'api/documents/documents.api';

const ViewCifa2025: React.FC = () => {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState<any>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const formatMunicipalPartnerType = (type: string): string => {
    switch (type) {
      case 'EMPAER-MT':
        return 'EMPAER MT';
      case 'EDUCATIONALINSTITUTION':
        return 'Instituições de Ensino';
      case 'ORGANIZATIONINSTITUTION':
        return 'Organizações - ONGs';
      case 'PRIVATEINSTITUTION':
        return 'Instituições Privadas/Outros';
      default:
        return '';
    }
  };

  const handleDownloadDocument = async (uuid: string) => {
    setLoading(true);

    try {
      const response = await getDocument(uuid);
      console.log(response);
      if (response) {
        // Cria um Blob com o conteúdo do arquivo
        const blob = new Blob([response], { type: 'application/pdf' });

        // Cria uma URL para o Blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento de âncora para forçar o download
        const a = document.createElement('a');
        a.href = url;
        a.download = `documento-${uuid}.pdf`; // Nome do arquivo

        // Adiciona o elemento ao DOM e dispara o clique
        document.body.appendChild(a);
        a.click();

        // Remove o elemento e libera a URL do Blob
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('PDF data not found.');
      }
    } catch (error) {
      setErrorMessage('Erro ao fazer download do documento!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const institutitonUuid = localStorage.getItem('institutionUuid') || '';
      const userAccessUuid = localStorage.getItem('userAccessUuid') || '';

      const payload: GetAnswersPayload = {
        institutitonUuid,
        userAccessUuid,
        referencedYear: 2025,
      };

      try {
        const response = await getAnswers(payload);
        const form = response.data.find((form) => form.formName === 'ASSISTÊNCIA TÉCNICA E EXTENSÃO RURAL');
        setAnswers(form?.answer);
      } catch (error) {
        setErrorMessage('Erro ao recuperar os dados da Assistência Técnica e Extensão Rural.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='CIFA - Visualizar Município - 2025' showBackButton={true} />

      <HorizontalStepper activeStep={0} />

      <PageTitle title='Visualizar Município' subtitle='Visualize os dados do Cadastro do Índice Municipal de Agricultura Familiar do Município.' />

      {answers && (
        <Stack marginX='4rem'>
          <Alert severity='info' sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}>
            <Typography variant='body2' fontSize='14px' textAlign='left' lineHeight={1.5}>
              Considera-se Assistência Técnica e Extensão Rural - ATER o número de atendimentos prestados aos beneficiários da agricultura familiar (unidade
              agro familiar) do município por profissional de ATER, por meio de, no mínimo, 1 (uma) visita presencial ao longo do ano.
            </Typography>
          </Alert>

          <ContainerForm title='Assistência Técnica e Extensão Rural'>
            <Stack direction='row' justifyContent='space-between'>
              <Stack flex={1}>
                <Typography variant='body2' sx={{ color: '#666666' }}>
                  Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada exclusivamente pelo Município?
                </Typography>
                <Typography variant='body1'>{answers?.aterExclusiveServiceMunicipality}</Typography>
              </Stack>

              <Stack flex={1}>
                <Typography variant='body2' sx={{ color: '#666666' }}>
                  O Município realizou parceria com uma instituição executora de Assistência Técnica e Extensão Rural - ATER em 2024?
                </Typography>
                <Typography variant='body1'>{answers?.aterServiceByMunicipalPartner ? 'Sim' : 'Não'}</Typography>
              </Stack>
            </Stack>

            {answers?.aterServiceByMunicipalPartner && (
              <Stack flex={1}>
                <Typography variant='body2' sx={{ color: '#666666' }}>
                  Informe a(s) Instituição(ões) em que foi realizada parceria:
                </Typography>
                <Typography variant='body1'>
                  {answers?.aterMunicipalPartnerList.map(
                    (item: any, index: any) => formatMunicipalPartnerType(item.name) + (answers?.aterMunicipalPartnerList.length === index + 1 ? '.' : ', '),
                  )}
                </Typography>
              </Stack>
            )}
          </ContainerForm>

          {answers?.aterServiceByMunicipalPartner &&
            answers?.aterMunicipalPartnerList.map((item: any, index: any) => (
              <ContainerForm key={index} title={formatMunicipalPartnerType(item.name)}>
                <Stack>
                  <Typography variant='body2' sx={{ color: '#666666' }}>
                    Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada pela instituição parceira no
                    Município?
                  </Typography>
                  <Typography variant='body1'>{item.numberOfBeneficiary}</Typography>
                </Stack>

                {item.partnershipDocumentUuid && (
                  <>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <Typography variant='body1' fontWeight={700}>
                        Documento de Formalização da Parceria
                      </Typography>
                      <Button startIcon={<DownloadIcon />} variant='outlined' onClick={() => handleDownloadDocument(item.partnershipDocumentUuid)}>
                        Baixar
                      </Button>
                    </Stack>

                    <Card
                      sx={{
                        paddingX: '1rem ',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: '#F5F5F5',
                        boxShadow: 0,
                        border: '1px solid #E4E4E4',
                      }}
                    >
                      <CardMedia>
                        <DescriptionOutlinedIcon />
                      </CardMedia>

                      <CardContent>
                        <Typography variant='body1'>documento-parceria</Typography>
                        <Typography variant='body2' sx={{ color: '#666666' }}>
                          Arquivo PDF
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                )}

                {item.ataCmdrsDocumentUuid && (
                  <>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <Typography variant='body1' fontWeight={700}>
                        Ata do CMDRS
                      </Typography>
                      <Button startIcon={<DownloadIcon />} variant='outlined' onClick={() => handleDownloadDocument(item.ataCmdrsDocumentUuid)}>
                        Baixar
                      </Button>
                    </Stack>

                    <Card
                      sx={{
                        paddingX: '1rem ',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: '#F5F5F5',
                        boxShadow: 0,
                        border: '1px solid #E4E4E4',
                      }}
                    >
                      <CardMedia>
                        <DescriptionOutlinedIcon />
                      </CardMedia>

                      <CardContent>
                        <Typography variant='body1'>ata-CMDRS</Typography>
                        <Typography variant='body2' sx={{ color: '#666666' }}>
                          Arquivo PDF
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                )}
              </ContainerForm>
            ))}

          <Stack direction={{ md: 'row-reverse' }} marginY='2rem' sx={{ gap: '1rem' }}>
            <FormButton title='Avançar' type={FormButtonType.PRIMARY} onClick={() => navigate(ROUTES.VIEW_FEDERAL_SCHOOL_FEEDING_2025)} isDisabled={loading} />
            <FormButton title='Voltar' type={FormButtonType.SECONDARY} onClick={() => navigate(ROUTES.CIFA2025)} />
          </Stack>
        </Stack>
      )}

      {errorMessage && <SnackbarFeedback isOpen type='error' message={errorMessage} />}
    </MenuProvider>
  );
};

export default ViewCifa2025;
