import React from 'react';

import { Box } from '@mui/material';

import { Header } from 'components/Home';
import { ModuleCard } from 'components/Shared';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import monitoringUser from '../../assets/icons/monitoring_icon_blue.svg';
import { ROUTES } from 'routes/constants';

const MenuService: React.FC = () => {
  return (
    <MenuProvider>
      <TopNavBar />

      <Box
        sx={{
          backgroundColor: '#0048B6',
          marginTop: '10px',
          height: '65px',
          alignContent: 'center',
        }}
      >
        <Header title='Gestão de Serviços' showBackButton={true} />

        <Box
          sx={{
            backgroundColor: '#0048B6',
            height: '67px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '25px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '90%',
              maxWidth: '1152px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              gap: '15px',
            }}
          >
            <ModuleCard
              title='CIFA - Cadastro do Índice Municipal de Agricultura Familiar'
              description='Gerencie todos os dados sobre o Cadastro do Índice Municipal de Agricultura Familiar disponíveis no sistema.'
              image={monitoringUser}
              path={ROUTES.MANAGE_CIFA}
            />
          </Box>
        </Box>
      </Box>
    </MenuProvider>
  );
};

export default MenuService;
