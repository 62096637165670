import { useNavigate } from 'react-router-dom';
import { Stack, Avatar, Typography, Button } from '@mui/material';

import { Colors } from 'theme';

export interface CardServicoProps {
  icone: JSX.Element;
  titulo: string;
  descricao: string;
  irPara: string;
  isVeiculo?: boolean;
}

export const CardServico = ({ icone, titulo, descricao, irPara, isVeiculo }: CardServicoProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Stack
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      borderRadius={2}
      boxShadow={2}
      padding='0.5rem'
      width={!isVeiculo ? { md: '30%', sm: '40%', xs: '100%' } : '80%'}
      height={{ md: '12rem', xs: '15rem' }}
      sx={{ backgroundColor: 'white' }}
    >
      <Avatar
        sx={{
          backgroundColor: Colors.AZUL_GOV,
          padding: '0.5rem',
        }}
      >
        {icone}
      </Avatar>

      <Typography mt={1} fontSize='1rem' fontWeight={500}>
        {titulo}
      </Typography>
      <Typography variant='caption' color='textSecondary'>
        {descricao}
      </Typography>

      <Button variant='text' onClick={() => navigate(irPara)} sx={{ color: Colors.AZUL_GOV }}>
        ACESSAR
      </Button>
    </Stack>
  );
};
