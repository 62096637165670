import React from 'react';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMenu } from 'contexts/menu/MenuContext';
import { SideMenu } from 'components/Shared';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

interface HeaderProps {
  title: string;
  showBackButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, showBackButton = false }) => {
  const { toggleMenu } = useMenu();
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.pathname === '/usuarios') {
      navigate(ROUTES.MENU_USER);
    } else if (location.pathname === '/usuarios/cadastrar') {
      navigate(ROUTES.MANAGE_USER);
    } else if (location.pathname.startsWith('/usuarios/visualizar/')) {
      navigate(ROUTES.MANAGE_USER);
    } else {
      navigate(ROUTES.HOME);
    }
  };

  return (
    <>
      <SideMenu />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#0048B6' }}>
        {showBackButton && (
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
        )}
        <Typography variant='h6' sx={{ color: '#FFFFFF', flexGrow: 1 }} textAlign='center'>
          {title}
        </Typography>
        <IconButton onClick={toggleMenu}>
          <MenuIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
      </Box>
      <Divider sx={{ backgroundColor: '#0D99FF' }} />
    </>
  );
};

export default Header;
