import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import { getAnswersSchoolFeeding } from 'api/answers/answers.api';
import { GetAnswersPayload } from 'api/answers/dtos/answers.dto';
import { Header } from 'components/Home';
import { ContainerForm } from 'components/Shared/ContainerForm';
import { FormButton, FormButtonType } from 'components/Shared/FormButton';
import { HorizontalStepper } from 'components/Shared/HorizontalStepper';
import { PageTitle } from 'components/Shared/PageTitle';
import { SnackbarFeedback } from 'components/Shared/SnackbarFeedback';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';

const ViewMunicipalSchoolFeeding2025: React.FC = () => {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState<any>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    const fetchData = async () => {
      const institutitonUuid = localStorage.getItem('institutionUuid') || '';
      const userAccessUuid = localStorage.getItem('userAccessUuid') || '';

      const payload: GetAnswersPayload = {
        institutitonUuid,
        userAccessUuid,
        referencedYear: 2025,
      };

      try {
        const response = await getAnswersSchoolFeeding(payload);
        const form = response.data.find(
          (form) => form.formName === 'ALIMENTAÇÃO ESCOLAR MUNICIPAL',
        );
        setAnswers(form);
      } catch (error) {
        setErrorMessage(
          'Erro ao recuperar os dados da Aba Alimentação Escolar (Municipal).',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MenuProvider>
      <TopNavBar />

      <Header
        title='CIFA - Visualizar Município - 2025'
        showBackButton={true}
      />

      <HorizontalStepper activeStep={3} />

      <PageTitle
        title='Visualizar Município'
        subtitle='Visualize os dados do Cadastro do Índice Municipal de Agricultura Familiar do Município.'
      />

      {answers && (
        <Stack marginX='4rem'>
          <ContainerForm title='Alimentação Escolar (PNAE Municipal)'>
            <Stack direction='row' justifyContent='space-between'>
              <Stack flex={1}>
                <Typography variant='body2' sx={{ color: '#666666' }}>
                  Valor disponibilizado pelo PNAE Municipal:
                </Typography>
                <Typography variant='body1'>
                  R${answers?.answer?.valueProvidedByPnae}
                </Typography>
              </Stack>

              <Stack flex={1}>
                <Typography variant='body2' sx={{ color: '#666666' }}>
                  Valores totais aplicados pelo município na Alimentação Escolar
                  oriundos de todo e qualquer fornecedor no ano de 2024:
                </Typography>
                <Typography variant='body1'>
                  R${answers?.answer?.valueAppliedByPnaeResource}
                </Typography>
              </Stack>
            </Stack>
          </ContainerForm>

          {(answers?.answer?.productAquiredFromCnpjByPnaeResourceList?.length ??
            0) > 0 && (
            <ContainerForm title='Alimentação Escolar (PNAE Municipal) - Produtos adquiridos via CNPJ'>
              {answers?.answer?.productAquiredFromCnpjByPnaeResourceList?.map(
                (item: any) => (
                  <Stack key={item.identification}>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        Nome/ Razão Social:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        CNPJ:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        Valor:
                      </Typography>
                    </Stack>

                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        {item.name}
                      </Typography>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        {item.identification}
                      </Typography>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        R${item.value}
                      </Typography>
                    </Stack>
                  </Stack>
                ),
              )}
            </ContainerForm>
          )}

          {(answers?.productAquiredFromCpfByPnaeResourceList?.length ?? 0) >
            0 && (
            <ContainerForm title='Alimentação Escolar (PNAE Municipal) - Produtos adquiridos via CPF'>
              {answers?.productAquiredFromCpfByPnaeResourceList?.map(
                (item: any) => (
                  <Stack key={item.identification}>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        Nome/ Razão Social:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        CNPJ:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: '#666666', flex: 1 }}
                      >
                        Valor:
                      </Typography>
                    </Stack>

                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        {item.name}
                      </Typography>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        {item.identification}
                      </Typography>
                      <Typography variant='body2' sx={{ flex: 1 }}>
                        R${item.value}
                      </Typography>
                    </Stack>
                  </Stack>
                ),
              )}
            </ContainerForm>
          )}

          <Stack
            direction={{ md: 'row-reverse' }}
            marginY='2rem'
            sx={{ gap: '1rem' }}
          >
            <FormButton
              title='Avançar'
              type={FormButtonType.PRIMARY}
              onClick={() => null}
              isDisabled={loading}
            />
            <FormButton
              title='Voltar'
              type={FormButtonType.SECONDARY}
              onClick={() => navigate(ROUTES.VIEW_STATE_SCHOOL_FEEDING_2025)}
            />
          </Stack>
        </Stack>
      )}

      {errorMessage && (
        <SnackbarFeedback isOpen type='error' message={errorMessage} />
      )}
    </MenuProvider>
  );
};

export default ViewMunicipalSchoolFeeding2025;
