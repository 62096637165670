import { createTheme } from '@mui/material/styles';

const theme = createTheme({});

export const Colors = {
  AZUL_GOV: '#0048B6',
  AMARELO_GOV: '#FDB913',
  VERMELHO_GOV: '#D32F2F',
  VERDE_GOV: '#007932',
  BRANCO_GOV: '#FFFFFF',
  CINZA_CLARO: '#F5F5F5',
  CINZA_ESCURO: '#00000099',
  CINZA_MEDIO: '#000000DE',
  BRANCO: '#FFFFFF',
};

export default theme;
