import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box, Chip } from '@mui/material';

interface SmallCardProps {
  icon: string;
  title: string;
  description: string;
  chips: string[];
  onAccess: () => void;
}

const SmallCard: React.FC<SmallCardProps> = ({ icon, title, description, chips, onAccess }) => {
  const [visibleChips, setVisibleChips] = useState<string[]>([]);
  const [hiddenChipCount, setHiddenChipCount] = useState(0);
  const chipsContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const calculateVisibleChips = () => {
      if (!chipsContainerRef.current) return;

      const containerWidth = chipsContainerRef.current.offsetWidth;
      let totalWidth = 0;
      let visibleChipIndex = 0;

      const chipPadding = 32;
      const chipMargin = 10;

      for (let i = 0; i < chips.length; i++) {
        const chipWidth = chipPadding + chips[i].length * 8;
        totalWidth += chipWidth + chipMargin;

        if (totalWidth <= containerWidth) {
          visibleChipIndex = i + 1;
        } else {
          break;
        }
      }

      setVisibleChips(chips.slice(0, visibleChipIndex));
      setHiddenChipCount(chips.length - visibleChipIndex);
    };

    calculateVisibleChips();

    window.addEventListener('resize', calculateVisibleChips);
    return () => {
      window.removeEventListener('resize', calculateVisibleChips);
    };
  }, [chips]);

  return (
    <Card sx={{ width: '276px', height: '258px', marginRight: '20px' }}>
      <CardContent>
        <img src={icon} alt='Icon' />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.2px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            color: '#000000DE',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.8px',
            letterSpacing: '0.17px',
            textAlign: 'center',
            color: '#00000099',
            marginTop: '5px',
          }}
        >
          {description}
        </Typography>
      </CardContent>

      <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflow: 'hidden', width: '100%' }} ref={chipsContainerRef}>
          {visibleChips.map((chip) => (
            <Chip
              key={chip}
              sx={{
                backgroundColor: '#EAEFF9',
                height: '22px',
                borderRadius: '4px 0px 0px 0px',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                marginRight: '10px',
              }}
              label={chip}
            />
          ))}
          {hiddenChipCount > 0 && (
            <Chip
              sx={{
                backgroundColor: '#EAEFF9',
                height: '22px',
                borderRadius: '4px 0px 0px 0px',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                marginRight: '10px',
              }}
              label={`+${hiddenChipCount} mais`}
            />
          )}
        </Box>

        <Button
          size='medium'
          sx={{
            width: '100%',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.40px',
            textAlign: 'left',
            color: '#0048B6',
          }}
          onClick={onAccess}
        >
          ACESSAR
        </Button>
      </CardActions>
    </Card>
  );
};

export default SmallCard;
