import React from 'react';
import { Card, CardContent, CardActions, Button, Chip } from '@mui/material';

interface MainCardProps {
  chips: string[];
  onAccess: () => void;
}

const MainCard: React.FC<MainCardProps> = ({ chips, onAccess }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '568px',
        boxShadow: '0',
      }}
    >
      <CardContent>
        {chips.map((chip) => (
          <Chip
            key={chip}
            sx={{
              backgroundColor: '#EAEFF9',
              height: '22px',
              borderRadius: '4px 0px 0px 0px',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginRight: '10px',
            }}
            label={chip}
          />
        ))}
      </CardContent>
      <CardActions sx={{ width: '100%' }}>
        <Button
          size='medium'
          sx={{
            width: '100%',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.40px',
            textAlign: 'left',
            color: '#0048B6',
          }}
          onClick={onAccess}
        >
          ACESSAR
        </Button>
      </CardActions>
    </Card>
  );
};

export default MainCard;
